@charset "UTF-8";
.class_name {
  font-size: large;
}

.pos_screen-section {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  background-color: var(--fg-color);
  padding: var(--padding-lg);
  padding-bottom: var(--padding-sm);
  align-items: center;
}

.quantity-field {
  grid-column: span 4/span 4;
  display: flex;
  align-items: center;
}

.pos_screen-button-field {
  grid-column: span 8/span 8;
  display: flex;
  align-items: center;
  margin: 5px;
}

.cart-container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--margin-md);
  position: relative;
  height: 90%;
  background-color: var(--fg-color);
  box-shadow: var(--shadow-base);
  border-radius: var(--border-radius-md);
}
.cart-container2 > .cart-label {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: var(--text-lg);
  padding-bottom: var(--padding-md);
}
.cart-container2 > .cart-totals-section {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  margin-top: var(--margin-md);
  padding: var(--padding-lg);
}
.cart-container2 > .cart-totals-section > .add-discount-wrapper {
  display: none;
  align-items: center;
  border-radius: var(--border-radius-md);
  border: 1px dashed var(--gray-500);
  padding: var(--padding-sm) var(--padding-md);
  margin-bottom: var(--margin-sm);
}
.cart-container2 > .cart-totals-section > .add-discount-wrapper > .add-discount-field {
  width: 100%;
}
.cart-container2 > .cart-totals-section > .add-discount-wrapper .discount-icon {
  margin-right: var(--margin-sm);
}
.cart-container2 > .cart-totals-section > .add-discount-wrapper .edit-discount-btn {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: var(--dark-green-500);
}
.cart-container2 > .cart-totals-section > .net-total-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-sm) 0px;
  font-weight: 500;
  font-size: var(--text-md);
}
.cart-container2 > .cart-totals-section > .item-qty-total-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-sm) 0px;
  font-weight: 500;
  font-size: var(--text-md);
}
.cart-container2 > .cart-totals-section > .taxes-container {
  flex-direction: column;
  font-weight: 500;
  font-size: var(--text-md);
}
.cart-container2 > .cart-totals-section > .taxes-container > .tax-row {
  display: flex;
  justify-content: space-between;
  line-height: var(--text-3xl);
}
.cart-container2 > .cart-totals-section > .grand-total-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-sm) 0px;
  font-weight: 700;
  font-size: var(--text-lg);
}

.tree {
  margin: 0;
  padding: 0;
}

/* Hide the nested list */
.tree ul {
  display: none;
  list-style-type: none;
  margin-left: 1rem;
}

.tree li {
  list-style-type: none;
}

/* Show the nested list when the list item is expanded */
.tree .caret::before {
  content: "▶";
  cursor: pointer;
}

.tree .caret-down::before {
  content: "▼";
}

.caret:hover {
  cursor: pointer;
}

.product-price {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-color);
  margin: var(--margin-sm) 0;
  margin-bottom: auto !important;
}
.product-price .striked-price {
  font-weight: 500;
  font-size: 15px;
  color: var(--gray-500);
}